<template>
  <div v-if="billing_account">
    <b-tabs lazy>
      <b-tab title="Delivery Orders">
        <DescriptorCard
          :fixed_params="$d.relationFixedParams('DeliveryOrder', 'BillTo', [billing_account])"
          :_index_params="$d.relationIndexParams('DeliveryOrder', 'BillTo', billing_account.id)"
          :stateIdentifier="'_billing_account_' + billing_account.id"
          class_name="DeliveryOrder"
          title="Delivery Orders"
        ></DescriptorCard>
      </b-tab>
      <b-tab title="Sales Return">
        <DescriptorCard
          :fixed_params="
            _.merge(
              $d.relationFixedParams('SalesReturn', 'BillTo', [billing_account]),
              $d.relationFixedParams('SalesReturn', 'UnderAccount', [account]),
            )
          "
          :_index_params="$d.relationIndexParams('SalesReturn', 'BillTo', billing_account.id)"
          :stateIdentifier="'_billing_account_' + billing_account.id"
          class_name="SalesReturn"
          title="Sales Returns"
        ></DescriptorCard>
      </b-tab>
      <b-tab title="Returnable Tangible Received">
        <DescriptorCard
          :fixed_params="$d.relationFixedParams('ReturnableTangibleReceived', 'ForAccount', [account])"
          :_index_params="$d.relationIndexParams('ReturnableTangibleReceived', 'ForAccount', account.id)"
          :stateIdentifier="'_account_' + account.id"
          class_name="ReturnableTangibleReceived"
          title="Returnable Tangible Received"
        ></DescriptorCard>
      </b-tab>
      <!-- TODO: Add OrderDelivered by Billing Account -->
      <!-- <b-tab title="Order Delivered">
        <DescriptorCard
          :fixed_params="$d.relationFixedParams('OrderDelivered', 'BillTo', [billing_account])"
          :_index_params="$d.relationIndexParams('OrderDelivered', 'BillTo', billing_account.id)"
          :stateIdentifier="'_billing_account_' + billing_account.id"
          class_name="OrderDelivered"
          title="Order Delivereds"
        ></DescriptorCard>
      </b-tab> -->
      <b-tab title="Dispenser Transfer Out">
        <DescriptorCard
          :fixed_params="$d.relationFixedParams('DispenserLocation', 'ToBillingAccount', [billing_account])"
          :_index_params="$d.relationIndexParams('DispenserLocation', 'ToBillingAccount', billing_account.id)"
          :stateIdentifier="'_billing_account_' + billing_account.id"
          class_name="DispenserLocation"
          title="DispenserLocation"
        ></DescriptorCard>
      </b-tab>
    </b-tabs>
  </div>
  <div v-else>
    <div class="alert alert-warning">Billing Account Required for Deliveries</div>
  </div>
</template>

<script>
export default {
  components: {
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
  },
  props: {
    billing_account: {
      required: true,
    },
    account: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {},
  methods: {},
  created() {
    this._ = _;
  },
};
</script>
